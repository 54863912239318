(function () {
	var inViewport = window.inViewport;
	var scrollListenerDelay = 100;
	var scrollListenerTimestamp = 0;
	var blocks = document.querySelectorAll('.component-timeline article');
	var hidden = Array.apply(null, Array(blocks.length)).map(Number.call, Number);

	function scrollListener (event) {
		if (typeof event !== 'undefined' && typeof event.keyCode !== 'undefined') {
			if ([33, 34, 35, 36].indexOf(event.keyCode) !== -1) {
				setTimeout(scrollListener, 500);
			}
			else {
				return false;
			}
		}

		var now = Date.now();

		// If there was enough delay since the last scroll
		if (now - scrollListenerTimestamp > scrollListenerDelay) {
			// Update the reference to the new timestamp
			scrollListenerTimestamp = now;

			var toHide = [];

			// Search for blocks which have not yet been set visible
			for (var i = 0; i < hidden.length; i++) {
				var idx = hidden[i];
				var isVisible = inViewport(blocks[idx], {
					offset: -0.5 * blocks[idx].offsetHeight
				});

				// Make the block visible
				if (isVisible) {
					toHide.push(idx);
					blocks[idx].classList.add('visible');
				}
			}

			// Remove visible blocks from the hidden list
			for (var j = 0; j < toHide.length; j++) {
				hidden.splice(hidden.indexOf(toHide[j]), 1);
			}

			// If no more block to set visible, remove the scroll listener
			if (!hidden.length) {
				window.removeEventListener('resize', scrollListener);
				window.removeEventListener('keypress', scrollListener);
				window.removeEventListener('scroll', scrollListener);
			}
		}
	}

	window.addEventListener('resize', scrollListener, false);
	window.addEventListener('keypress', scrollListener, false);
	window.addEventListener('scroll', scrollListener, false);
	window.setTimeout(function () {
		document.querySelector('.component-timeline').classList.add('animated');
		scrollListener();
	}, 1000);
}());
