(function () {
	// nodes
	var boxShadowNodes = document.querySelectorAll('#card, .component-timeline article');
	// var textShadowNode = document.body;

	// params
	var data = {
		// ratio to decrease the impact of the device orientation
		ratio: 2
	};

	var status = false;

	if (window.DeviceOrientationEvent) {
		function deviceOrientationListener (event) {
			// alpha is the compass data.alphaection the device is facing in degrees
			data.alpha = event.alpha;

			// beta is the front-to-back tilt in degrees, where front is positive
			data.beta = event.beta;

			// gamma is the left-to-right tilt in degrees, where right is positive
			data.gamma = event.gamma;

			// if in landscape mode, invert the orientation
			if (window.innerWidth > window.innerHeight) {
				data.beta = event.gamma;
				data.gamma = event.beta;
			}

			// update the s<a href="#"></a>hadow of the main container
			for (var i = 0; i < boxShadowNodes.length; i++) {
				boxShadowNodes[i].style.boxShadow = (data.gamma / data.ratio) + "px " + (data.beta / data.ratio) + "px 20px #666";
			}

			// update the text shadow globally
			// textShadowNode.style.textShadow = (data.gamma / data.ratio) + "px " + (data.beta / data.ratio) + "px 5px #999";
		}

		// Listen for the event and handle DeviceOrientationEvent object
		window.addEventListener('deviceorientation', deviceOrientationListener, false);
	}
}());
